import create from 'zustand';
import { persist } from 'zustand/middleware';
import logInUserWithMagic from './logInUserWithMagic';
import getSignedInUser from './getSignedInUser';
import saveEmployeeInfo from './saveEmployeeInfo';
import logoutUser from './logoutUser';
import verifyAccess from './verifyAccess';
import createEmployeeAndBusinessProfiles from './createEmployeeAndBusinessProfiles';
import getCompanyInfo from './getCompanyInfo';
import getCompanies from './getCompanies';

const initialState = {
  isAuthorized: false,
};

const useStore = create(persist(
  (set, get) => ({
    user: initialState,
    companies: undefined,
    company: undefined,
    isLoadingCompanies: false,
    logInUserWithMagic: logInUserWithMagic(set, get),
    getSignedInUser: getSignedInUser(set, get),
    saveEmployeeInfo: saveEmployeeInfo(set, get),
    logoutUser: logoutUser(set, get),
    createEmployeeAndBusinessProfiles: createEmployeeAndBusinessProfiles(set, get),
    verifyAccess: verifyAccess(set, get),
    getCompanyInfo: getCompanyInfo(set, get),
    getCompanies: getCompanies(set, get),
  }),
  {
    name: 'user-storage',
    partialize: (state) => Object.fromEntries(
      Object.entries(state).filter(([key]) => !['companies'].includes(key)),
    ),
  },
));

export default useStore;
