import { postWithAuthorization, patchWithBody } from '../../lib/async-helpers';
import getUserHandler from './handlers/getUser';

export default function logInUserWiIdthMagic(set, get) {
  return async ({ email }, token) => {
    try {
      const response = await fetch('/api/login', postWithAuthorization(token));
      const json = await response.json();
      // if user email has been found in session, verify if has been onborded
      let hasProfile = false;
      let hasOnboarded = get().user?.hasOnboarded;
      if (!get().user?.hasOnboarded) {
        const userAccessId = get().user?.userId;
        const onboardedResponse = await fetch(`/api/users/${email}/onboard`, patchWithBody({ userAccessId }));
        const onboardedJson = await onboardedResponse.json();
        if (onboardedJson?.success) {
          hasOnboarded = true;
        }
      }

      if (json?.user?.email) {
        const user = await getUserHandler(json?.user?.email);
        hasProfile = user.rows?.length > 0;
      }

      set({
        user: {
          ...get().user,
          email,
          hasProfile,
          hasOnboarded,
          isSessionValid: true,
        },
      });
    } catch (e) {
      console.error(`An error has occurred while trying to login: ${e.message}`);
      set({ error: e.message });
    }
  };
}
