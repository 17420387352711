export default function logoutUser(set, get) {
  return async () => {
    set({ isLoading: true });

    try {
      const result = await fetch('/api/logout');
      const json = await result.json();
      set({
        user: {
          email: undefined,
          hasOnboarded: undefined,
          hasUserLoggedOut: json?.success,
          isAuthorized: false,
        },
        isLoading: false,
      });
    } catch (e) {
      console.error(`Failed to retrieve user data ${e}`);
      set({ error: e.message, isLoading: false });
    }
  };
}
